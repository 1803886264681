
    :root {
        --primary: #06181e;
        --green : #38B2A9;
        --secondary: #102C32;
        --secondary2: #25314B;
        --button1: #454655;
        --black: #1C1F2A;
        --white: #F1F7FC;
    }
    

@font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/Roboto-Regular.ttf);
}

body {
    margin: 0px;
    font-family: roboto;
    background-color: var(--primary) !important;
}

body::-webkit-scrollbar {
    display: none;
}