.depositeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 69px);
    width: 100%;
}

/* .depositeContainer {
    padding: 16px;
    background: linear-gradient(to right, var(--secondary2), var(--primary));
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    width: 500px;
    height: auto;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
} */

.depositeContainer{
    width: 500px;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    align-items : center;
    flex-direction: column;
}
.depositeContainer::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.depositeContainer::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 100% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.depositeTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: var(--white);
}

.depositeAmountContainer {
    margin-top: 32px;
}

.depositeCheckboxContainer {
    margin-top: 32px;
}

.depositeSendContainer {
    margin-top: 32px;
}
.formContainer {
position: relative;
z-index: 999;
}
.inputDisabled {
    height: 58px;
    width: 96.5%;
    font-size: 18px;
    padding-left: 8px;
    color: var(--white) !important;
    border-radius: 100px;
    border: 1px solid var(--white);
    /* background-color: var(--grren); */
}

.copyButton {
    margin-top: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: transparent !important;
    /* border: 1px solid var(--white) !important; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    background-color: var(--green) !important;
}