.blogWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogTitle {
    width: 100%;
}

.blogTitle p {
    color: var(--white);
    font-size: 2.5vw;
    font-weight: bold;
    letter-spacing: -0.02rem;
}

.scrollBlogContainer {
    width: 100%;
    display: flex;
    overflow-x: scroll;
}

.scrollBlogContainer::-webkit-scrollbar {
    display: none;
}

.blogContainer {
    margin-right: 16px;
    background-color: var(--secondary);
    height: 350px;
    border-radius: 24px;
    width: 350px !important;
}

.blogContainer p {
    margin: 0;
    padding: 16px 16px 0px 16px;
}

.blogContainer h3 {
    margin: 0;
    padding: 16px;
    font-weight: 500;
    height: 90px;
    color: var(--white);
}

.footerNewsContainer {
    display: flex;
    justify-content: center;
    background-color: var(--green);
    margin: 0px 16px;
    padding: 8px;
    border-radius: 8px;

}

.footerNewsContainer h5 {
    margin: 0;
    padding-right: 16px;
    color: #031a1d;
}

.blogContainer a {
    padding-left: 16px;
    text-decoration: none;
    color: var(--white);
    font-weight: bold;
    text-align: center;
}

@media (max-width:1250px) {
    .blogContainer h1 {
        font-size: 26px;
        height: 80px;

    }
}

@media (max-width:900px) {
    .blogWrapper {
        height: 130vh;
        /* margin-top: 64px; */
    }

    .blogContainer {
        margin-top: 8px;
    }
}

@media (max-width:1050px) {
    .blogContainer h1 {
        font-size: 24px;
    }
}

@media (max-width:600px) {
    .blogWrapper {
        height: 100vh;
        /* margin-top: 64px; */
    }
}

@media (max-width:500px) {
    .blogTitle p {
        font-size: 2rem;
    }
}

@media (max-width:350px) {
    .blogTitle p {
        font-size: 1.8rem;
    }
}