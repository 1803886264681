.SignupWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
}

/* .SignupContainer {
    position: relative;
    background: linear-gradient(180deg, var(--secondary2), var(--primary));
    width: 60%;
    min-height: 70vh;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
} */
.SignupContainer{
    width: 60%;
    min-height: 70vh;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    align-items : center;
    flex-direction: column;
}
.SignupContainer::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.SignupContainer::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 100% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.SignupContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.SignupContainerChildOne h1{
    font-size: 3.5vw;
    color: var(--white);
}
.SignupArrowBack {
    position: absolute;
    top: 40px;
    margin-left: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.SignupContainer .SignupContainerChildOne h1 {
    margin-bottom: 16px;
}

form {
    width: 70% !important;
}

@media (max-width:700px) {
    .SignupContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SignupContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

h1 {
    margin-bottom: 32px;
    color: var(--secondary)
}

.signupsubmitButton {
    padding: 16px !important;
    width: 100%;
    border-radius: 100px !important;
    background-color: var(--green) !important;
}

.date {
    background-color: transparent !important;
    height: 58px !important;
    width: 100%;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none !important;
    border-radius: 100px !important;
    border: 1px solid var(--white) !important;
    color: var(--white);
    cursor: pointer;
    padding-left: 16px;
}
.phoneContainer div div div::before{
    border-bottom: none !important;
    color: var(--white) !important;
}
.phoneContainer div div div{
    color: var(--white) !important;
}
.phoneNumber{
    border: 1px solid var(--white) !important;
    height: 58px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    color: var(--white) !important;
}

@media (max-width:1250px) {
    .SignupArrowBack {
        top: 25px;
    }
}
