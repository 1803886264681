.SigninWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
}
.signinErrorMessage{
    color: red;
    background-color: rgba(255, 0, 0, 0.3);
    width: 100%;
    padding: 16px;
    text-align: center;
    border-radius: 16px;
}
/* .SigninContainer {
    position: relative;
    background: linear-gradient(180deg, var(--secondary2), var(--primary));
    width: 60%;
    min-height: 55vh;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
} */
.SigninContainer{
    width: 60%;
    min-height: 55vh;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    align-items : center;
    flex-direction: column;
}
.SigninContainer::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.SigninContainer::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 100% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.SigninContainerChildOne {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 999999;
}
.SigninContainerChildOne h1{
    font-size: 3.5vw;
    color: var(--white);
}
.SigninArrowBack {
    position: absolute;
    top: 40px;
    margin-left: 24px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.inputContainer div label {
   color: var(--white);
}
.inputContainer div div input{
    border: 1px solid var(--white);
    border-radius: 100px;
    color: var(--white);
}

fieldset{
    border-radius:100px !important;
    border: 1px solid var(--white) !important;

}

@media (max-width:1250px) {
    .SigninArrowBack {
        top: 30px;
    }

    .SideSigninContainer h1 {
        font-size: 45px;
    }

    .SideSigninSubTitle {
        font-size: 20px;
    }

}

@media (max-width:1000px) {
    .SideSigninContainer h1 {
        font-size: 40px;
    }

    .SideSigninSubTitle {
        font-size: 15px;
    }

}

.SideSigninSignup {
    margin-top: 32px;
    font-size: 16px;
    color: var(--white);
}

form {
    width: 70% !important;
}

@media (max-width:700px) {
    .SigninContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SigninContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

h1 {
    margin-bottom: 64px;
    color: var(--secondary)
}


.signinsubmitButton {
    padding: 16px !important;
    width: 100% !important;
    border-radius: 100px !important;
    background-color: var(--green) !important;
}