.mobileAppWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobileAppContainer{
    width: 80%;
    height: 80%;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    align-items : center;
    flex-direction: column;
}
.mobileAppContainer::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.mobileAppContainer::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 100% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.mobileAppContainer h1{
    color: var(--white);
    font-size: 3vw;
    text-align: center;
}

.mobileAppContent{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    height: 100%;
}

.mobileAppContentData{
   max-width: 48%;
}

.mobileAppContentData h2{
    color: var(--white);
}
.mobileAppContentData h3{
    color: var(--white);
    opacity: 0.5;
}