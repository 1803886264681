/* .transactionsWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    margin-top: 64px;
    background: linear-gradient(to right, var(--secondary2), var(--primary));
    min-width: 700px !important;
    height: 500px;
    aspect-ratio: 1/1;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
} */
.transactionsWrapper{
    min-width: 700px !important;
    height: 500px;
    aspect-ratio: 1/1;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}
.transactionsWrapper::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(30% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.transactionsWrapper::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 80% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.transactionstTitleContainer {
    margin: 0px;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    text-align: center;
}

.transactionsContainer {
    padding: 16px;
    height: 100%;
    border-radius: 0px 0px 8px 8px;
    overflow-y: auto;
    position: relative;
    z-index: 9999;
}

.transactionsContainer::-webkit-scrollbar {
    display: none;
}

.transactionsTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.transactionsInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}