.bodyWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.bodyVideoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
    background:linear-gradient(360deg, #06181e 0%, rgba(6, 24, 30, 0) 100%);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: inset 0px -100px 80px rgba(5, 25, 30, 1) !important; */
}

.overlayText h1{
    color: var(--white);
    font-size: 4.8vw;
}

.overlayText p{
    color: var(--white);
    font-size: 1.5vw;
    opacity: 0.8;
    width: 70%;
}

.overlayTextButton{
    background-color: var(--green) !important;
    color: var(--white) !important;
    border-radius: 100px !important;
    padding: 8px 32px !important;
    font-size: 1.4vw !important;
    text-transform: capitalize !important;
}

.overlayTextVolume{
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.315);
    padding: 8px 24px;
    border-radius: 0px 32px 32px 0px;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
