.cryptoCurrencyWrapper{
    padding: 32px 0px;
}

.cryptoCurrencyWrapper h1{
    text-align: center;
    color: var(--white);
    font-size: 2.5vw;
}

.cryptoCurrencyAccordion{
    background-color: transparent !important;
    color: var(--white) !important;
}