.featuresWrapper{
    padding: 48px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}

.featuresCard{
    background-color: var(--secondary);
    width: 350px;
    border-radius:32px;
    padding: 16px;
}
.featuresCard h2{
    color: var(--white);
    margin-top: 0px;
}
.featuresCardContent{
    margin-bottom: 16px;
}
.featuresCardContent:last-child{
border-bottom: none;
}

.featuresCardContentBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.featuresCardContentBox img {
    background-color: var(--white);
    padding: 8px;
    border-radius: 100px;
}
.featuresLoading{
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featuresnegChange {
    margin: 0;
    color: red;
    font-weight: bold;
}

.featuresposChange {
    font-weight: bold;
    margin: 0;
    color: green;
}