/* .profileWrapper {
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    background: linear-gradient(to right, var(--secondary2), var(--primary));
    margin-top: 64px;
    width: 500px;
    height: 450px;
    aspect-ratio: 1/1;
    border-radius: 32px !important;
    display: flex;
    flex-direction: column;
} */
.profileWrapper{
    width: 500px;
    height: 450px;
    aspect-ratio: 1/1;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 0px 15px;
    padding: 16px;
    overflow: hidden;
    position: relative;
    background-color: #001216;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}
.profileWrapper::before{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(30% 50% at 0% 0%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.profileWrapper::after{
    content: '';
    position: absolute;
    width: 1168px;
    height: 1196px;
    background: radial-gradient(50% 50% at 80% 50%, #f9f590 0%, rgba(249, 245, 144, 0) 100%);
    opacity: 0.15;
    filter: blur(40px);
}

.profileTitleContainer {
    margin: 0px;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
    color: white;
    text-align: center;
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.emailInputContainer {
    display: flex;
}
.emailInputContainer div{
    flex: 1;
}

.nameInputContainer div p,
.emailInputContainer div p,
.usernamePassInputContainer div p,
.walletInputContainer p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
}

.walletInputContainer {
    margin-left: 16px;
}

.emailInputContainer {
    margin-top: 0px;
}

.nameInputContainer {
    display: flex;
}
.nameInputContainer div{
    flex: 1;
}
.usernamePassInputContainer {
    display: flex;
}
.profileinputDisabled{
    height: 40px;
    font-size: 18px;
    padding-left: 8px;
    color: var(--white) !important;
    border-radius: 100px;
    border: 1px solid var(--white);
}